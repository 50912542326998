.footerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;   
    /* padding: 1rem; */
}
a{
  color: #ffff;
  text-decoration: none;
}
a:hover{
  color: #ffff;
}

.footerContainer > p {
    cursor: pointer;
    margin-right: 1rem;
    font-weight: 600;
}

@media (max-width: 768px) {
    .footerContainer  {
        flex-direction: column;
        }
  .footerContainer > p {
    margin-top: 1rem;
    margin-right: 0px;
    width: 200px;
  }    
}
