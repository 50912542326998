.setup{
    padding: 40px;
    letter-spacing: 0.5px;
}
.setup > h2 {
    margin-top: 1.5em;
}

.headerLinks {
    margin-bottom: 1rem;
    cursor: pointer;
    text-decoration: underline;
}