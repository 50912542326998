@keyframes flashAnimation {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    50% {
      opacity: 0.8;
      transform: scale(1.2);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .flashy-button {
    background-color: #ff5733;
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 50%;
    margin: auto;
    animation: flashAnimation 1.5s infinite;
  }
  
  .flashy-button:hover {
    background-color: #ff6f47;
  }