.button{
    background-color: var(--primary);
    border-radius: 10px;
    color: white;
    padding: 10px;
    border:none;
    width: 60%;
    font-weight: 600;
    margin-top: 16px;
}

.buttonDisabled{
    background-color: #959e9e;
    border-radius: 10px;
    color: white;
    padding: 10px;
    border:none;
    /* width: 60%; */
    font-weight: 600;
    margin-top: 16px;
}