.carousel-root {
    flex: 1;
}

.carousel {
    display: flex;
    justify-content: left;
}



.slide {
    text-align: left;

}


@media (min-width: 100px) and (max-width: 878px) {
    .productDisplayReverse {
        flex-direction: column;
    }

    .productDisplay {
        flex-direction: column;

    }

    .buyNowBtn {
        width: 100%;
        cursor: pointer;
    }

    .carousel {
        justify-content: center;
    }

    .productTile_productTitle__\+4qQl {
        text-align: center;

        text-wrap: balance;
    }

    .productTile_productSubTitle__seUly {
        text-align: center;

        text-wrap: balance;
    }

    .productTile_price__iqDbj {

        justify-content: center;
    }

    .productTile_discountPriceTitle__iRgCR {
        text-align: center;

    }




    .carousel.carousel-slider .control-arrow {
        display: none;

    }

    .carousel .carousel-status {
        display: none;

    }

    .carousel .thumbs {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        overflow: hidden;
        transform: none !important;
    }

    .carousel .control-dots {
        display: none;
    }

    .row-hero-header {
        width: 100%;

    }

    .screenshot-carousel .owl-dots {
        display: none;
    }

    .row-hero-header-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}