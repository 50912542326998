.productDisplay{
    flex-direction: row;
    margin: 20px;
}

.productDisplayReverse{
    flex-direction: row-reverse;
    margin: 40px;
}

.label-order{
    color: 'black';
    font-weight: bolder;
}

.input-order{
    padding: 10px;
    border: 2px solid var(--primary);
    border-radius: 5px;
    width: 500px;
}

/* Media query for screens with a maximum width of 600 pixels */
@media only screen and (max-width: 480px) {
    /* Styles for screens up to 600 pixels wide */
    .productDisplayReverse{
        flex-direction: column;
        padding: 5;
    }
    .productDisplay{
        flex-direction: column;
        padding: 5;
    }

    .input-order{
        width: 70vw;
    }
  
    /* Additional styles as needed for smaller screens */
  }
  