.productTitle{
    font-size: 30px;
    color:black
    }
    .productSubTitle{
        font-size: 18px;
        color: #7a7878;
    }
    .price{
        display: flex;
        padding-top: 50px;
        /* justify-content: space-around; */
    }
    .priceTitle{
        font-size: 25px;
        color:black;
    }
    .oldPriceTitle{
        padding-top: 8px;
        font-size: 18px;
        color: #7a7878;
        text-decoration: line-through;
        padding-left: 30px;
    }
    .discountPriceTitle{
        color: #4babab;
        font-size: 18px;
        font-weight: 500;
    }